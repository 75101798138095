import {
  DedicatedCategory,
  DedicatedRelation,
  createMappingInstance,
  isDefined,
  translations,
} from "@timeedit/registration-shared";
import { useMemo } from "react";
import { RegistrationDesktop } from "./RegistrationDesktop";
import { RegistrationMobile } from "./RegistrationMobile";
import {
  AllocationControlContext,
  ChangeRequestContext,
  DateIntervalContext,
  IdContext,
  LoadActionContext,
  RegistrationContext,
  UtilContext,
} from "./RegistrationContexts";
import { useIsDesktop } from "../../utils/hooks";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { RegistrationProps } from "./types";
import { uniq } from "lodash";

dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

/**
 *
 * @mappingData - Mapping data
 * @registration - Everything fetched from registration backend, includes all that necessary for the registration page
 * @locale - The locale
 * @allowedDateInterval - The allowed date interval for the registration nothing can be shown out of these bounds
 * @dateInterval - The current date interval you have filtered on
 * @signal - The signal data used to display the signal summary
 * @allocationControlActions - What happens when user press the different allocation control buttons
 * @returns The registration page
 */

export function RegistrationMain({
  studentId,
  mappingData,
  registration,
  allowedDateInterval,
  dateInterval,
  signal,
  allocationControlActions,
  mode = "student",
  navigate,
  dedicatedTracksActions,
  changeRequests,
  loadActions,
  params,
}: RegistrationProps) {
  const mapping = createMappingInstance({ mappingData });
  const { id } = params;

  const isDesktop = useIsDesktop();

  const defaultId = useMemo(() => {
    const defaultId = Object.values(registration.courses)[0]?.children?.[0];
    return isDesktop ? defaultId : undefined;
  }, [isDesktop, registration.courses]);

  const registrationContext = useMemo(
    () => ({
      registration,
      mode,
    }),
    [registration, mode]
  );

  const idContext = useMemo(
    () => ({
      id: Number(id ?? defaultId),
      studentId,
      setId: navigate,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultId, id, studentId]
  );

  const utilContext = useMemo(
    () => ({
      navigate,
      translations,
      mapping,
      dedicatedTracksActions: isDefined(dedicatedTracksActions)
        ? dedicatedTracksActions
        : {
            presentCategory: (data: DedicatedCategory[]) =>
              "Category: " +
              data
                .reduce((result: string[], it) => {
                  const alreadyExists = result.some((r) => r === it.value);
                  if (alreadyExists) return result;
                  return [...result, it.value];
                }, [])
                .join(", "),
            presentRelation: (data: DedicatedRelation[]) =>
              "Relation: " + uniq(data.map((d) => d.id)).join(", "),
          },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const dateIntervalContext = useMemo(
    () => ({
      allowedDateInterval,
      dateInterval,
    }),
    [allowedDateInterval, dateInterval]
  );

  const changeRequestContext = useMemo(
    () => ({
      changeRequests: changeRequests ?? [],
    }),
    [changeRequests]
  );

  // This is needed because of custom views for the calendar. We need to pass
  // some props to the list view, and have not found a good way to pass props
  // directly to it beyond initial values. We need one context per thing that
  // should change together for performance reasons.
  //
  // We should resist using the context for any lower level components that we
  // need to be reusable for other things.
  return (
    <RegistrationContext.Provider value={registrationContext}>
      <IdContext.Provider value={idContext}>
        <AllocationControlContext.Provider value={allocationControlActions}>
          <LoadActionContext.Provider value={loadActions}>
            <UtilContext.Provider value={utilContext}>
              <DateIntervalContext.Provider value={dateIntervalContext}>
                <ChangeRequestContext.Provider value={changeRequestContext}>
                  {isDesktop ? (
                    <RegistrationDesktop signal={signal} />
                  ) : (
                    <RegistrationMobile signal={signal} />
                  )}
                </ChangeRequestContext.Provider>
              </DateIntervalContext.Provider>
            </UtilContext.Provider>
          </LoadActionContext.Provider>
        </AllocationControlContext.Provider>
      </IdContext.Provider>
    </RegistrationContext.Provider>
  );
}
